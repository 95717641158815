const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Siddhesh Koli | Portfolio",
  description:
    "A Data Engineer and Big Data Cloud Specialist who thrives to automate business processes with AI based solutions with a blend of Data Science",
  og: {
    title: "Siddhesh Portfolio",
    type: "website",
    url: "http://siddheshkoli.com/",
  },
};

//Home Page
const greeting = {
  title: "Namaskar!",
  sub: "Siddhesh Koli",
  logo_name: "Siddhesh Koli",
  resumeLink: "https://drive.google.com/file/d/1YkrsxQ9QNErbiFgJ5ihJw5RNp1XZc4zl/view?usp=sharing",
};

// home logos
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Github",
      iconifyClassname: "simple-icons:github",
      style: {
        color: "white",
      },
      profileLink: "https://github.com/LEGEND0WSKI",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/profile/PennyTraitor",
    },
    {
      siteName: "LinkedIn",
      iconifyClassname: "simple-icons:linkedin",
      style: {
        color: "#0077b5",
      },
      profileLink: "https://www.linkedin.com/in/siddheshkoli/",
    },
    {
      siteName: "HashNode",
      iconifyClassname: "simple-icons:hashnode",
      style: {
        color: "#2962ff",
      },
      profileLink: "https://hashnode.com/@LEGENDOWSKI",
    },
  ],
};

//what i do?
const skills = {
  data: [
    {
      title: "Data Science & Analysis",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing scalable models for various business and statistical use cases",
        "⚡ Experience in working with Statistical data modelling and analysis",
        "⚡ Having good hands-on with Data Cleaning, processing & Analysing using Pandas",
        "⚡ Proficient in Machine Learning Algorithms SUpervised and Unsupervised",
        "⚡ Expertise in storytelling by Data Visualization using Tableau & PowerBI"
      ],
      softwareSkills: [
        {
          skillName: "SQL",
          fontAwesomeClassname: "fa-database", 
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "simple-icons:pandas",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "NumPy",
          fontAwesomeClassname: "logos-numpy",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Tableau",
          fontAwesomeClassname: "logos-tableau",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Machine Learning",
          fontAwesomeClassname: "logos-machine-learning",
          style: {
            color: "#1572B6",
          },
        },
      
      ],
    },
    {
      title: "Big Data",
      fileName: "DesignImg",
      skills: [
        "⚡ Cloud computing expertise with cloud platforms like AWS and Google Cloud",
        "⚡ Extensive experience with Hadoop and Spark for distributed data processing",
        "⚡ Knowledge of NoSQL databases like HBase, Cassandra, MongoDB and HDFS",
        "⚡ Skilled in designing, building, and maintaining pipelines using Airflow",
        "⚡ Expertise in data visualization using Tableau and PowerBI"
        
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "logos-aws",
          style: {
            backgroundColor: "transparent",
          },
        },
        
        {
          skillName: "Docker",
          fontAwesomeClassname: "logos-docker-icon",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Cassandra",
          fontAwesomeClassname: "logos-cassandra",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Hadoop",
          fontAwesomeClassname: "logos-hadoop",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Scala",
          fontAwesomeClassname: "logos-scala",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Linux", //logos airflow
          fontAwesomeClassname: "logos-linux",
          style: {
            color: "#2572B6",
          },
        },
      ],
    },
    {
      title: "Web Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Ability to design, develop, and deploy full-stack applications ",
        "⚡ Creating application backend in Node",
        "⚡ Having experience in Domain & Hosting using Vercel and Netlify",
        "⚡ Skilled with APIs used for communication between front & backend",
        "⚡ Experience working in Agile teams and participating in sprints",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
       
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js", //find fa nosde
          style: {
            color: "#339933",
          },
        },
      ],
    }
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
};

//project cards
// colors:[primary,secondary,success,danger,warning,info,light,dark,]
const projectcards = {
  list: [
    {
      title: "Reddit ETL",
      img_path: "reddit_etl.png",
      description:
        "Finding top posts on Reddit using Apache Airflow and Docker",
      tags: [
        {
          lang: "python",
          color: "#004782",
        },
        {
          lang: "Docker",
          color: "#820032",
        },
        {
          lang: "Airflow",
          color: "#00a100",
        },
        {
          lang: "AWS Athena",
          color: "#ff4b4b",
        },
        {
          lang: "Tableau",
          color: "#3c0095",
        },
      ],
      link: " ",
      code: "https://github.com/LEGEND0WSKI/Reddit-ETL-Airflow",
      linkcolor: "white",
    },

    {
      title: "ETL on ATM transactions",
      img_path: "sparnord.png",
      description:
        "Analysis on  Denmarks top Financial company using Amazon Redshift and Big Data technologies.",
      tags: [
        {
          lang: "AWS",
          color: "red",
        },
        {
          lang: "python",
          color: "#004782",
        },
        {
          lang: "Sqoop",
          color: "#c47206",
        },
        {
          lang: "PySpark",
          color: "#4257f5",
        },
        {
          lang: "Amazon Redshift",
          color: "#ff4b4b",
        },
      ],
    },
    {
      title: "Analysis on Customer Purchasing Behavior",
      img_path: "countdata.png",
      description:
        "Modelling regression models on count data to accurately predict customer purchasing behavior.",
      tags: [
        {
          lang: "python",
          color: "#004782",
        },
        {
          lang: "numpy",
          color: "#8700b0",
        },
        {
          lang: "scipy",
          color: "#c47206",
        },
        {
          lang: "seaborn",
          color: "#ff4b4b",
        },
        {
          lang: "excel",
          color: "#3c0095",
        },
      ],
    },
    {
      title: "Lead Scoring Model",
      img_path: "leadscore.png",
      description:
        "A logistic regression machine learning model to predict if the customer is a potential lead or not by assigning a score.",
      tags: [
        {
          lang: "python",
          color: "004782",
        },
        {
          lang: "pandas",
          color: "#ff4b4b",
        },
        {
          lang: "numpy",
          color: "#8700b0",
        },
        {
          lang: "Seaborn",
          color: "#1e4b4b",
        },
        {
          lang: "Statistics",
          color: "#4a0095",
        },
      ],
      link: "",
      code: "",
      linkcolor: "white",
    },
        
  ],
};

const resumeleft = {
  left: {
    title: ["Experience"],
    data: [
      {
        title: "Student Consutant",
        subtitle: "CommoditEdge LLC. Dallas, USA",
        date: "Jan 2024 - May 2024",
        content: [
          "Conducted market share & competition research to identify target customers for energy-based EV charger startup.",
          "Streamlined logistics sourcing and created Gantt charts to visualize our financial roadmap and key milestones.",
          "Developed business plan and stratergies in an Agile Scrum enviromnent.",
          "Analyzed Real estate prices best suited for startup throughout the country for Tier 2 cities closest to Tier 1 cities",
          
        ],
      },
      {
        title: "SAP Consultant",
        subtitle: "WhiteSpace Global (TATA Trent Ltd.), Mumbai, India",
        date: "April 2020 - June 2020",
        content: [
          "Ensured system integrity by producing daily reports with SAP in the Reconciliation Team at Tata Trent Ltd.",
          "Consolidated SAP Hana & Shopify E-commerce data for Finance & IT departments for Tata CliQ, Westside and Zudio.",
          "Optimized business processes by identifying and resolving system errors for over 200 retail stores",
          "Automated weekly and monthly excel reports in a custom dashboard for visualizing and summing up issues in Shopify, SAP and other data improving report generation 5 times faster.",
        
        ],
      },
      {
        title: "ReactJS Intern",
        subtitle: "Agnostech, Mumbai, India",
        date: "April 2019 - July 2019",
        content: [
          "Created clones of webites like Amazon, Instagram, Facebook, using ReactJs and NodeJS",
          "Published individual forms depending on client requests",
        ],
      },
    ],
  },
};

const resumeright = {
  right: [
    {
      title: ["Education"],
      data: [
        {
          title: "M.S in Information Technology and Management",
          subtitle: "University of Texas, Dallas",
          date: "2022 - 2024",
          content: ["GPA: 3.57/4.00"],
        },
        {
          title: "B.E in Electronics Engineering",
          subtitle: "Mumbai University",
          date: "2016 - 2020",
          content: ["CGPA : 6.57/10.0"],
        },
      ],
    },
    {
      title: ["Leadership Experience"],
      data: [
        {
          title: "IEEE",
          subtitle: "R1 racing event head",
          content: [
            "Organised and conducted Nationl R1 robot racing event in Mumbai",

          ],
        },
        {
          title: "Pratishtha",
          subtitle: "Event head",
          content: [
            "Organised Robotics Workshop for F2 robots for Technical fest Pratistha.",
          ],
        },
      ],
    },

  ],
};

//certificate cards
const certifications = {
  certifications: [
        {
      title: "Python",
      subtitle: "HackerRank",
      logo_path: "python-01.png",
      certificate_link: "https://www.hackerrank.com/profile/PennyTraitor",
      alt_name: "HackerRank",
      color_code: "#000000",
    },  
    {
      title: "Python Bootcamp",
      subtitle: "Udemy",
      logo_path: "udemy-01.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-d01e45c7-afc5-4b91-bab6-a2729766dd41/",
      alt_name: "Udemy",
      color_code: "#000000",
    },
    {
      title: "SQL Bootcamp",
      subtitle: "Udemy",
      logo_path: "udemy-01.png",
      certificate_link:
        "https://www.udemy.com/certificate/UC-d2bea24b-b51b-4507-86ae-334553ad54cd/",
      alt_name: "Udemy",
      color_code: "#000000",
    },
    {
      title: "Advanced Certificate Data Science",
      subtitle: "IIIT Bangalore",
      logo_path: "iiitb-01.png",
      certificate_link:
        "https://www.credential.net/cdddb8e1-9d14-49bf-ba60-1d8b41d7ea54#gs.d10l7o",
      alt_name: "",
      color_code: "#000000",
    },
    {
      title: "DS C39 Prep Certificate ACPDS",
      subtitle: "upGrad",
      logo_path: "upgrad-01.jpg",
      certificate_link:
        "https://www.credential.net/fce2169d-2080-4ba4-af66-dd94a111d1cf#gs.d10jzk",
      alt_name: "",
      color_code: "#000000",
    },
    // {
    //   title: "",
    //   subtitle: "",
    //   logo_path: "coursera-01.png",
    //   certificate_link:
    //     "",
    //   alt_name: "",
    //   color_code: "#000000",
    // },
    // {
    //   title: "Data Visualization",
    //   subtitle: "",
    //   logo_path: "coursera-01.png",
    //   certificate_link:
    //     "",
    //   alt_name: "",
    //   color_code: "#000000",
    // },
    // {
    //   title: "",
    //   subtitle: "DataCamp",
    //   logo_path: "datacamp-01.png",
    //   certificate_link:
    //     "",
    //   alt_name: "DataCamp",
    //   color_code: "#000000",
    // },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "contact.jpg",
    description:
      "You can message me, I will reply within 24 hours.",
  },
  addressSection: {
    title: "Address",
    subtitle: "Richardson, Texas-75082",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+1 (945)232-9885",
  },
};

//contact icons
const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/LEGEND0WSKI",
    iconifyClassname: "simple-icons:hackerrank",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/siddheshkoli/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:siddhesh.koli32@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  projectsHeader,
  contactPageData,
  projectcards,
  certifications,
  resumeleft,
  resumeright,
};
